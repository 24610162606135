import { makeAutoObservable, runInAction } from "mobx";

class Store {
  capacities = [];
  defaultCapacity = {
    days: 0,
    hours: 0,
    ratio: 0,
  };
  isLoading = false;
  loginRequired = true;
  loginUrl = "...";
  boards = [];
  boardId = "";
  refreshTime = "";
  sprints = [];
  sprintId = "";

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  setRefreshTime(time) {
    this.refreshTime = time;
  }

  async getJsonFromApi(url) {
    const response = await fetch(url);
    if (!response.status || response.status >= 400) {
      throw "API error!";
    }
    return await response.json();
  }

  async postForJsonFromApi(url, data) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.status || response.status >= 400) {
      throw "API error!";
    }
    return await response.json();
  }

  async checkLogin() {
    const login = await this.getJsonFromApi("/api/get-login");
    runInAction(() => {
      if (typeof login === "string") {
        this.loginUrl = login;
        this.loginRequired = true;
      } else {
        this.loginRequired = false;
      }
    });
  }

  async getBoards() {
    const boards = await this.getJsonFromApi("/api/boards");
    runInAction(() => {
      this.boards = boards;
    });
  }

  setBoard(boardId) {
    this.sprintId = "";
    this.sprints = [];
    this.boardId = boardId;
  }

  setCapacities(capacities) {
    this.capacities = capacities;
  }

  getRawCapacityData(boardId, sprintId) {
    const closedAssigneesUrl = "/api/boards/" + boardId + "/closed/assignees";
    const sprintIssuesUrl = `/api/boards/${boardId}/sprints/${sprintId}`;
    return Promise.all([
      this.getJsonFromApi(closedAssigneesUrl),
      this.getJsonFromApi(sprintIssuesUrl),
    ]);
  }

  async getCapacities(boardId, sprintId) {
    this.boardId = boardId;
    this.sprintId = sprintId;
    if (!boardId || !sprintId) {
      return;
    }
    this.setLoading(true);

    // get the data
    const [
      mostRecentClosedSprintAssignees,
      sprint,
    ] = await this.getRawCapacityData(this.boardId, this.sprintId);
    let issues = sprint.issues;

    // combine assignees
    let assignees = new Set(
      issues.map((item) => item.fields.assignee.displayName)
    );
    mostRecentClosedSprintAssignees.forEach((item) => {
      assignees.add(item);
    });
    let capacity = [...assignees].sort().map((item) => {
      return {
        displayName: item,
        estimate: 0,
        timeSpent: 0,
      };
    });

    // calculate
    issues.forEach((issue) => {
      if (!issue.fields.assignee || !issue.fields.assignee.displayName) {
        return;
      }

      const { displayName } = issue.fields.assignee;
      const {
        originalEstimateSeconds,
        timeSpentSeconds,
      } = issue.fields.timetracking;
      let personalCapacity = capacity.find(
        (personalCapacity) => personalCapacity.displayName === displayName
      );

      personalCapacity.estimate += originalEstimateSeconds ?? 0;
      personalCapacity.timeSpent += timeSpentSeconds ?? 0;
    });

    this.setCapacities(capacity);
    this.setLoading(false);
  }

  async getCapacity(displayName, setCapacity) {
    if (!displayName || !this.boardId || !this.sprintId) {
      return;
    }
    const response = await fetch(
      `/api/capacities/${displayName}_${this.boardId}_${this.sprintId}`
    );
    const responseObject = await response.json();
    if (!responseObject.displayName) {
      // default values come back with no display name
      responseObject.displayName = displayName;
    }
    setCapacity(responseObject);
  }

  async getSprints(boardId) {
    const sprints = !boardId
      ? []
      : await this.getJsonFromApi(`/api/boards/${boardId}`);
    runInAction(() => {
      this.sprints = sprints;
    });
  }

  setSprint(sprintId) {
    this.sprintId = sprintId;
  }

  savePersonalCapacity(newPersonalCapacity, displayName) {
    this.postForJsonFromApi(
      `/api/capacities/${displayName}_${this.boardId}_${this.sprintId}`,
      newPersonalCapacity
    );
  }
}

const storeInstance = new Store();
export default storeInstance;
