import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import store from "../store/Store";
import SprintSelector from "./SprintSelector";

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: "none",
  },
  dropDown: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  listLink: {
    color: "inherit",
    textDecoration: "none",
  },
}));

const BoardSelector = () => {
  useEffect(() => {
    store.getBoards();
  }, []);

  const location = useLocation();
  const boardPathPortion = location.pathname.match(/\/\d+/);

  const classes = useStyles();
  return (
    <div>
      <FormControl className={classes.dropDown}>
        <InputLabel>Board</InputLabel>

        <Select
          value={
            store.boards.length && boardPathPortion ? boardPathPortion[0] : ""
          }
        >
          {store.boards.map((board) => (
            <MenuItem key={board.id} value={`/${board.id}`}>
              <Link className={classes.listLink} to={`/${board.id}`}>
                {board.name}
              </Link>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Switch>
        <Route path={`/:boardId`}>
          <SprintSelector />
        </Route>
      </Switch>
    </div>
  );
};

export default observer(BoardSelector);
