import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

import { observer } from "mobx-react-lite";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { colors } from "@bblabs/styles";

import RefreshButton from "./components/RefreshButton";
import BoardSelector from "./components/BoardSelector";
import SprintSelector from "./components/SprintSelector";
import CapacityBoard from "./components/CapacityBoard";
import store from "./store/Store";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "'Lato', sans-serif",
    fontStyle: "italic",
    color: colors.taosSky,
  },
  selectors: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

const App = () => {
  const classes = useStyles();

  useEffect(() => {
    store.checkLogin();
  }, []);

  if (store.loginRequired) {
    return <a href={`${store.loginUrl}?path=${window.location.pathname}`}>Click here to login</a>;
  }

  const requestedPath = window.location.search.replace("?path=", "");
  if (requestedPath) {
    window.location.href = window.location.origin + requestedPath;
  }

  return (
    <div>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              cpcty
            </Typography>
          </Toolbar>
        </AppBar>

        <Router>
          <div className={classes.selectors}>
            <BoardSelector />
          </div>
        </Router>

      </div>
    </div>
  );
};

export default observer(App);
