import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { Refresh } from "@material-ui/icons";
import { colors } from "@bblabs/styles";

import store from "../store/Store";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    margin: theme.spacing(1),
    verticalAlign: "bottom",
    margin: theme.spacing(1),
    marginBottom: 0,
    position: "relative",
  },
  fabProgress: {
    color: colors.tutu,
    position: "absolute",
    top: 6,
    left: 6,
    zIndex: 1,
  },
}));

const RefreshButton = (props) => {
  const classes = useStyles();
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!store.isLoading) {
      store.setRefreshTime(new Date());
    }
  };

  const disabled = !store.sprintId || store.isLoading;
  const color = disabled ? "inherit" : "primary";

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="delete"
        onClick={handleButtonClick}
        disabled={disabled}
      >
        <Refresh color={color} />
      </IconButton>

      {store.isLoading && (
        <CircularProgress size={36} className={classes.fabProgress} />
      )}
    </div>
  );
};
export default observer(RefreshButton);
