import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { colors } from "@bblabs/styles";

import Chart from "./CapacityChart";
import PersonalCapacityEditor from "./PersonalCapacityEditor";
import store from "../store/Store";

const hourInSeconds = 60 * 60;

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 345,
  },
  media: {
    padding: theme.spacing(1),
    height: 240,
  },
  cardContent: {
    paddingTop: 0,
  },
  nameLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  nameWrapper: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  name: {
    marginBottom: 0,
    color: colors.boulder,
  },
}));

const PersonalCapacity = (props) => {
  const { displayName, estimate, timeSpent } = props.capacity;
  const balance = estimate - timeSpent;

  const [personalCapacity, setPersonalCapacity] = useState(
    store.defaultCapacity
  );
  useEffect(() => {
    store.getCapacity(displayName, setPersonalCapacity);
  }, [store.sprintId]);

  const getFormattedHours = (seconds) => {
    const hours = seconds / hourInSeconds;
    return hours.toFixed(2);
  };

  const classes = useStyles();
  const capacity =
    personalCapacity.days * personalCapacity.hours * personalCapacity.ratio;
  const estimateInHours = getFormattedHours(estimate);
  const timeSpentInHours = getFormattedHours(timeSpent);
  const balanceInHours = getFormattedHours(balance);

  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.root}>
        <Chart
          estimate={estimateInHours}
          timeSpent={timeSpentInHours}
          remaining={balanceInHours}
          capacity={capacity}
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.nameLine}>
            <div className={classes.nameWrapper}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.name}
              >
                {displayName}
              </Typography>
            </div>
            <div>
              <PersonalCapacityEditor
                displayName={displayName}
                personalCapacity={personalCapacity}
                setPersonalCapacity={setPersonalCapacity}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default observer(PersonalCapacity);
