import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import store from "../store/Store";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      marginTop: 0,
      width: 42,
    },
  },
}));

const PersonalCapacityEditor = (props) => {
  const { displayName, personalCapacity, setPersonalCapacity } = props;

  useEffect(() => {
    store.getCapacity(displayName, setPersonalCapacity);
  }, [store.sprintId]);

  const handlePropertiesChange = (event, propertyName) => {
    const newPersonalCapacity = {
      ...personalCapacity,
    };
    newPersonalCapacity[propertyName] = event.target.value;
    setPersonalCapacity(newPersonalCapacity);
    if (event.target.value) {
      store.savePersonalCapacity(newPersonalCapacity, displayName);
    }
  };

  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        label="Days"
        size="small"
        value={personalCapacity.days}
        onChange={(event) => {
          handlePropertiesChange(event, "days");
        }}
      />
      <TextField
        label="Hours"
        size="small"
        value={personalCapacity.hours}
        onChange={(event) => {
          handlePropertiesChange(event, "hours");
        }}
      />
      <TextField
        label="Ratio"
        size="small"
        value={personalCapacity.ratio}
        onChange={(event) => {
          handlePropertiesChange(event, "ratio");
        }}
      />
    </form>
  );
};

export default observer(PersonalCapacityEditor);
