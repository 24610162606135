import React from "react";
import Chart from "react-apexcharts";
import { colors } from "@bblabs/styles";

const CapacityChart = (props) => {
  const options = {
    chart: {
      type: "bar",
      height: 240,
    },
    colors: [colors.taosSky, colors.bluebee, colors.tutu],
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    xaxis: {
      categories: ["Estimate", "Spent", "Balance"],
      min: Math.min(0, props.remaining),
      max: props.capacity + 10,
      labels: {
        style: {
          colors: [colors.silverSandAlt],
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    grid: {
      padding: {
        left: 16,
        right: 32,
        top: 8,
        bottom: 0,
      },
    },
    annotations: {
      xaxis: [
        {
          x: props.capacity,
          borderColor: colors.trafficCone,
          borderWidth: 2,
          label: {
            borderColor: colors.trafficCone,
            style: {
              color: "#fff",
              background: colors.trafficCone,
            },
            text: parseFloat(props.capacity).toFixed(2),
            orientation: "horizontal",
          },
        },
      ],
    },
  };

  const series = [
    {
      data: [props.estimate, props.timeSpent, props.remaining],
    },
  ];

  return <Chart options={options} series={series} type="bar" height={140} />;
};

export default CapacityChart;
