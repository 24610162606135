import React from "react";
import App from "./App";

import { colors } from '@bblabs/styles';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.taosSky,
    },
    secondary: {
      main: colors.tutu,
    },
  },
});

const ThemedApp = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  );
};

export default ThemedApp;