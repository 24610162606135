import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import store from "../store/Store";
import CapacityBoard from "./CapacityBoard";

const useStyles = makeStyles((theme) => ({
  root: {},
  dropDown: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  list: {
    listStyle: "none",
  },
  listLink: {
    color: "inherit",
    textDecoration: "none",
  },
}));

const SprintSelector = () => {
  const { boardId } = useParams();
  useEffect(() => {
    store.getSprints(boardId);
  }, [boardId]);

  let { path, url } = useRouteMatch();
  const location = useLocation();
  const sprintPath = location.pathname.match(/\/\d+\/\d+/);
  const menuDataLoaded =
    store.sprints.length && location.pathname && sprintPath;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl className={classes.dropDown}>
        <InputLabel>Sprint</InputLabel>
        <Select value={menuDataLoaded ? location.pathname : ""}>
          {store.sprints.map((sprint) => (
            <MenuItem key={sprint.id} value={`/${boardId}/${sprint.id}`}>
              <Link className={classes.listLink} to={`${url}/${sprint.id}`}>
                {sprint.name}
              </Link>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Switch>
        <Route path={`${path}/:sprintId`}>
          <CapacityBoard />
        </Route>
      </Switch>
    </div>
  );
};

export default observer(SprintSelector);
