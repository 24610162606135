import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import PersonalCapacity from "./PersonalCapacity";
import store from "../store/Store";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: `calc(100% - ${theme.spacing(1)}px)`,
  },
}));

const CapacityBoard = () => {
  const { boardId, sprintId } = useParams();
  useEffect(() => {
    store.getCapacities(boardId, sprintId);
  }, [sprintId, store.refreshTime]);

  const classes = useStyles();

  if (!boardId || !sprintId) {
    return null;
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      {store.capacities.map((capacity) => (
        <PersonalCapacity key={capacity.displayName} capacity={capacity} />
      ))}
    </Grid>
  );
};

export default observer(CapacityBoard);
